import React from "react"
import './project.scss'
import ProjectInfo from './project__info'
import ProjectImages from './project__images'
import ProjectText from './project__text'
import { Parallax } from 'react-scroll-parallax'
import Img from 'gatsby-image'


// import { useController } from 'react-scroll-parallax'
// import { withController } from 'react-scroll-parallax'



// Dieser Komponent soll sich um die Logik kümmern wie die Projekte gerendert werden
const Project = ({ project }) => {
    
    // const { parallaxController } = useController();
    const images = project.images
    const tags = project.tags
    const infobox = project.infobox
    const layout = project.layout; // According to name in CMS
    // const typeTest = Math.floor(Math.random() * 2) + 1

    // const ParallaxCache = () => {
    //     const { parallaxController } = useController();
     
    //     useLayoutEffect(() => {
    //         const handler = () => parallaxController.update();
    //         window.addEventListener('load', handler);
    //         return () => window.removeEventListener('load', handler);
    //     }, [parallaxController]);
     
    //     return null;
    // };
    // const handleLoad = () => {
    //     // updates cached values after image dimensions have loaded
    //     parallaxController.update();
    // };

    console.log(project.images);

    return(
        <div className={`project`} data-layout={layout}>
            {
                images ? 
                <>
                    <ProjectInfo tags={tags} infobox={infobox} project={project} showProjectText={true} />
                    <ProjectImages onClick="full_size" images={images} layout={layout} />

                    {
                        project.parallax ?
                        <> 
                            <Parallax className="parallax" y={[100, -100]} tagOuter="figure" slowerScrollRate >
                                    {/* <img src={project.parallax} /> */}

                                <Img fluid={project.parallax.childImageSharp.fluid} />
                            </Parallax>
                        </>
                        : false
                    }
                    
                </>
                : 
                <>
                    <ProjectInfo tags={tags} infobox={infobox} project={project} showProjectText={false}/>
                    <ProjectText project={project} />
                </>
            }
        </div>
    )
}

export default Project