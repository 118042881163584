import React from "react";
import './modal.scss';

export const Modal = ({ onOpen, children }) => {
  return <div onClick={onOpen}> {children}</div>;
};

export const ModalContent = ({ onClose, children }) => {
  return (
    <div className="modal">
      <span className="close" onClick={onClose}>
        &times;
      </span>
        <div className="modalcontent">
          {children}
        </div>
    </div>
  );
};