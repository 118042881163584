import React from 'react'
import './project__info.scss'

const ProjectInfo = ({ project, tags, infobox, showProjectText }) => {

    return(
        <div className="project__info">
            <div className="project__info__header">
                <div className="project__info__tags">
                    <h2 dangerouslySetInnerHTML={{__html: project.title }} />   
                    {
                        tags ? tags.map((tag, i) => (
                            <div key={i} className="project__info__tags__tag">
                                <p dangerouslySetInnerHTML={{ __html: tag.tag }} />
                            </div>
                        )) : false
                    }
                </div>
                <div className="project__info__infobox">
                    {
                        infobox ? infobox.map((info, i) => (
                            <p key={i}>
                                <span dangerouslySetInnerHTML={{ __html: info.label }} />:
                                <span dangerouslySetInnerHTML={{ __html: info.text }} />
                            </p>
                        )) : false
                    }
                </div>
            </div>
            {
                showProjectText ? 
                <div className="project__info__body">
                    <p dangerouslySetInnerHTML={{__html: project.text }} />
                </div>
                : false
            }
        </div>
    )
}

export default ProjectInfo