import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Project from '../components/sections/project'
import IntroText from '../components/sections/introText'
import { ParallaxProvider } from 'react-scroll-parallax';



const orderByDate = (a, b) => {
  console.log(a, b)
  const currentDate = a.childMarkdownRemark.frontmatter.date
  const nextDate = b.childMarkdownRemark.frontmatter.date
  
  if (currentDate < nextDate) {
    return 1;
  }
  if (currentDate > nextDate) {
    return -1;
  }
}

const IndexPage = ({ data }) => {
  console.log(data.allFile.nodes)
  // GraphQL (unten in diesem File) ist über die variable »data« oben in diesem Komponenten als props abrufbar
  // in der Variable »projects« werden alle projekte als Array gesammelt:
  const projects = data.allFile.nodes.sort((a, b) => orderByDate(a, b));
  const intro = data.file.childMarkdownRemark.frontmatter.intro;
  // file(sourceInstanceName: {eq: "content"}, name: {eq: "about"}) {
  //   id
  //   childMarkdownRemark {
  //     frontmatter {
  // unkommentiere die nächste Zeile um den Inhalt in der Konsole zu sehen
  //console.log(projects)
  
  return(

    <Layout>
      <SEO title="Home" />
        <ParallaxProvider>
          <div className="projects">
              <IntroText text={intro}/>
              {
                // wir mappen dir projekte und rendern sie als komponent, der soll sich um die weitere logik kümmern 
                // count geben wir weiter damit der komponent text/bild abwechseln links/rechts ausrichtet

                projects.map((project, i) => {
                  const projectData = project.childMarkdownRemark.frontmatter

                  return(
                    <Project key={i} project={projectData}/>
                  )
                })
              }
          </div>
        </ParallaxProvider>
    </Layout>
  )
}

export default IndexPage

// GraphQL Grob erklärt:
// Durchsuche all Files vom Typ »content«  aber ignoriere das File mit dem Namen »About«
// Da es sich um eine File Sammlung handelt muss das Resultat ein Array sein, 
// selbst wenn nur ein Eintrag oder sogar keiner enthalten ist

export const indexQuery = graphql`
  query indexQuery {
    file(sourceInstanceName: {eq: "content"}, name: {eq: "about"}) {
      id
      childMarkdownRemark {
        frontmatter {
          title
          text
          intro
          founder
          exhibitions {
            text
          }
          contributers {
            text
            name
          }
        }
      }
    }

    allFile (filter: {relativeDirectory: {eq: "project"}}) {
      nodes{
        id
        childMarkdownRemark {
          frontmatter {
            title
            text
            intro
            founder
            images {
              text
              image{
                childImageSharp {
                  fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            infobox {
              label
              text
            }
            tags {
              tag
            }
            parallax{
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            layout
            citations {
              number
              text
            }
          }
        }
      }
    }
  }
 `