import React from 'react'
import { useState } from "react"
import './project__images.scss'
import { Modal, ModalContent } from "./modal"
import ImageText from './image_caption'
import Img from 'gatsby-image'

const ProjectImages = ({ images, layout, i }) => {
    console.log(layout)
    if(!images) return false
    return(
        <div className="project__images">
            { layout === 1 ? <Type1 images={images} className={`type__${1}`}/> : false } 
            { layout === 2 ? <Type2 images={images} className={`type__${2}`}/> : false } 
            { layout === 3 ? <Type3 images={images} className={`type__${3}`}/> : false } 
            { layout === 4 ? <Type4 images={images} className={`type__${4}`}/> : false } 
            { layout === 5 ? <Type5 images={images} key={i} className={`type__${5}`}/> : false } 
            { layout === 6 ? <Type6 images={images} className={`type__${6}`}/> : false } 
        </div>
    )
}


// ZWEISPALTIG / Rechte Spalte zweispaltig
const Type1 = ({ images, className }) => {
    
    const columnLeft = images.slice(0, 2)   // Nimm die ersten beiden Bilder
    const columnRight = images.slice(2, images.length+1) // Nimm die restlichen Bilder

    return(
        <div className="holder">
            <div className={className}>
                <ImageMap images={columnLeft} className="" />
                <ImageMap images={columnRight} className="" />
            </div>
        </div>
    )
}


// ZWEISPALTIG / Rechte Spalte zweispaltig
const Type2 = ({ images, className }) => {
    
    const columnLeft = images.slice(0, 1)   // Nimm die ersten beiden Bilder

    const columnRightHalf = Math.floor(images.length/2)+1

    const columnRightHalf1 = images.slice(1, columnRightHalf) // Nimm die restlichen Bilder
    const columnRightHalf2 = images.slice(columnRightHalf, images.length) // Nimm die restlichen Bilder

    return(
        <div className="holder">
            <div className={className}>
                <ImageMap images={columnLeft} className="" />
                <ImageMap images={columnRightHalf1} className="" />
                <ImageMap images={columnRightHalf2} className="" />
            </div>
        </div>
    )
}


// 1 und 3 column
const Type3 = ({ images, className }) => {
    
    const columnLeft = images.slice(0, images.length-1)   // Nimm die ersten beiden Bilder
    // const columnRight = images.slice(images.length-1, images.length)
    const columnRight = [images[images.length-1]]

    return(
        <div className="holder">
            <div className={className}>
                <ImageMap images={columnLeft} className="" />
                <ImageMap images={columnRight} className="" />
            </div>
        </div>
    )
}

const Type4 = ({ images, className }) => {
    
    const columnLeft = [images[images.length-2]]   // Nimm das erste Bild (1/2)
    const columnRight = [images[images.length-1]] // Nimm das andere Bild (2/2)

    return(
        <div className="holder">
            <div className={className}>
                <ImageMap images={columnLeft} className="" />
                <ImageMap images={columnRight} className="" />
            </div>
        </div>
    )
}

const Type5 = ({ images, className, i, image }) => {
    
    const columnLeft = [images[images.length-3]]   // Nimm das erste Bild
    const columnRight = images.slice(1) // Nimm die restlichen Bilder

    // const toggleModal = url => {
    //     setShowModal(!showModal);
    //     setImgUrl(url);
    //   };

    return(
        <>
            <div className="holder">
                <div className={className}>
                    <ImageMap images={columnLeft} key={i} className="" />
                    <ImageMap images={columnRight} key={i} className="" />
                </div>
            </div>
    
        </>
    )
}

const Type6 = ({ images, className }) => {
    
    const columnLeft = images.slice(0, 2)   // Nimm die ersten beiden Bilder

    const columnRightHalf = Math.floor(images.length/2)+1

    const columnRightHalf1 = images.slice(2, columnRightHalf) // Nimm die restlichen Bilder
    const columnRightHalf2 = images.slice(columnRightHalf, images.length) // Nimm die restlichen Bilder

    return(
        <div className="holder">
            <div className={className}>
                <ImageMap images={columnLeft} className="" />
                <ImageMap images={columnRightHalf1} className="" />
                <ImageMap images={columnRightHalf2} className="" />
            </div>
        </div>
    )
}

const ImageMap = ({images, className, text}) => (
    <div>
        {
            images.map((image, i) => {
                return( 
                    <Image key={i} className={className} image={image} />
                )
            })
        }
    </div>
)

const Image = ({ image, className }) => {
    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);
    console.log(image.image)

    if(!image.image) return false

    const sources = [
        image.image.childImageSharp.fluid
    ]

    return(
        <>
        <Modal onOpen={showModal}>
            <div className={`project__images__image ${className ? className : ""}`}>
                {/* <img src={image.image} /> */}

                <Img fluid={sources} />
            </div>
        </Modal>
        {isOpen && (
            <ModalContent onClose={() => setIsopen(false)}>
            <div>
                <div className="popup">
                    <Img
                    fluid={sources}
                    imgStyle={{ objectFit: 'contain' }}
                    />
                </div>
                    {
                        image.text ? 
                        <div className="image__captions">
                            <span dangerouslySetInnerHTML={{__html: image.text }} />
                        </div>
                        : false
                    }
                </div>
            </ModalContent>
          )}
        </>
    )
}


 
export default ProjectImages