import React from 'react'
import './project__text.scss'

const toHTML = (value) => {
    return value
    // return remark()
    //     .use(remarkHTML)
    //     .processSync(value)
    //     .toString()
}

// const YourPreviewTemplate({ entry, getAsset }) => {
//   const asMarkdown = entry.getIn(['data', 'something']) 
//   const asHTML = toHTML(asMarkdown)


const CitationItemNumberText = ({ list }) => {
    console.log(list);
    return(
    <div className="text_citations">
        {
            list ? list.map((item, i) => (
                <p key={i} className="citation_items">
                    <span dangerouslySetInnerHTML={{ __html: item.number }} />
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </p>
            )) : false
        }
    </div>
)
}




const ProjectText = ({ project, image, i }) => {
    // console.log(project.text);
    const citation = project.citations;
    const text = toHTML(project.text)
    console.log(project);

    return(
        <>
        <div className="project__text">
            <div dangerouslySetInnerHTML={{__html: text }} />
            <div className="citation_list">
            <CitationItemNumberText list={citation} />  
            </div>
        </div>
        </>
    )
}

export default ProjectText