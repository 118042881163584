import React from "react"
import './introText.scss'

// Dieser Komponent soll sich um die Logik kümmern wie die Projekte gerendert werden
const IntroText = ({ text }) => (
    <div className="projects__intro">
      <p dangerouslySetInnerHTML={{__html: text }} />
    </div>
)

export default IntroText